label {
  width: 100px;
  margin-right: 10px;
  text-align: right;
  display: inline-block;
}

input {

}

.login-button {
  width: 200px;
  margin: 10px 30px;
  background-color: #4abf4a;
  padding: 8px;
  font-size: 1.2em;
  color: white;
  font-weight: bold;
  border-radius: 8px;
}

.errors {
  color: red;
}